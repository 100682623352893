export default {
  state: {
    hideLeftNav: false,
    hideLeftTollNav: false
  },
  mutations: {
    SET_HIDE: (state, value) => {
      state.hideLeftNav = value
    },
    SET_TOLL: (state, value) => {
      state.hideLeftTollNav = value
    }
  },
  actions: {
    ChangeHide ({ commit }, data) {
      commit('SET_HIDE', data)
    },
    ChangeTollHide ({ commit }, data) {
      commit('SET_TOLL', data)
    }
  },
  getters: {

  }
}
