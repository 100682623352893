<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less">
@import "./assets/css/reset.less";

html,
body {
  height: 100%;
  margin: 0;
  background-color: #EFF0F9;
  min-width: 1200px;
  color: #030303;
}

#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

/*设置宽度,轨道颜色*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #c6c6c6;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: #e6e6e6;
  border-radius: 10px;
}

.line-clamp-1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line-clamp-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.noData {
  padding-top: 75px;
  color: #5E6272;
  text-align: center;
  .emptyimg {
    width: 175px;
  }
  .empty-text {
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    margin-top: 2px;
  }
}

</style>
