import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Carousel, CarouselItem, Message, Popover, MessageBox, Dialog, Input, Tabs, TabPane, Pagination, Empty, Progress, Image, Drawer } from 'element-ui'
import './assets/theme/index.css'
import storage from '@/utils/storage'// 引入
import VueRouter from 'vue-router'
import VueClipBoard from 'vue-clipboard2'

import Directive from './directives' // 自定义指令

if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
  window.location.href = 'https://chat.huashuyunai.com/#/'
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(Directive)

Vue.use(VueClipBoard)

Vue.prototype.$storage = storage// 加入vue的原型对象

Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Pagination)
Vue.use(Empty)
Vue.use(Progress)
Vue.use(Image)
Vue.use(Drawer)
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
